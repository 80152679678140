import messageMixin from "./messageMixin";

export default {
  mixins: [messageMixin],
  mounted() {
    window.scroll(0, 0);
  },
  methods: {
    async handleLoadData() {
      const me = this;

      try {
        if (Object.hasOwn(me, 'isBusy')) {
          me.isBusy = true;
        }
        
        const result = await me[me.repository].getAll();
        me.records = result.data;
      } catch (error) {
        const { status } = error.data
        me.records = [];
        if (status == 419) {
          me.$bvModal.show("expired-token-model");
        } else if (status == 403) {
          me.$bvModal.show("session-finish-modal");
        }
      } finally {
        if (Object.hasOwn(me, 'isBusy')) {
          me.isBusy = false;
        }
      }
    },

    async saveData() {
      const me = this;
      try {
        let formData = null;
        if (Object.hasOwn(me, 'isBusy')) {
          me.isBusy = true;
        }

        if (Object.hasOwn(me, 'isAdmin')) {
          formData = me.formData
        } else {
          formData = { ...me.formData }
        }

        const uuid = formData.uuid ? formData.uuid : null
        const option = uuid ? 'update' : 'save'

        await me[me.repository][option](uuid, formData)
        me.$emit('save');
      } catch (error) {
        const { message, status } = error.data;
        if (status == 419) {
          me.$bvModal.show("expired-token-model");
        } else if (status == 403) {
          me.$bvModal.show("session-finish-modal");
        }
        me.errorSave(message)
      } finally {
        if (Object.hasOwn(me, 'isBusy')) {
          me.isBusy = false;
        }
      }
    },

    async save() {
      const me = this
      me.messageSuccess();
      me.$bvModal.hide(me.id);
      if (Object.hasOwn(me, 'isTravel')) {
        await me.findDateStartAndDateEnd();
      } else {
        await me.handleLoadData();
      }
    },

    errorSave(message) {
      const me = this

      let title = 'Error al guardar registro' 
      let messageErr = 'Registro no guardado.';

      if (message == 'Email is registered') {
        title = 'Error Correo electrónico';
        messageErr = 'Correo electrónico ya está en uso.'
      } else if (message == 'Identification is registered') {
        title = 'Error Identificación';
        messageErr = 'Identificación ya esta en uso.'
      } else if (message == 'Username is registered') {
        title = 'Error Usuario';
        messageErr = 'Usuario ya esta en uso.'
      } else if (message == 'NIT is registered') {
        title = 'Error NIT';
        messageErr = 'Nit ya esta en uso.'
      } else if (message == 'The tour has active sales') {
        title = 'Error Tickets vendidos';
        messageErr = 'Tienes tickets vendidos para este tour. No puedes cambiar el transporte. Debes crear un nuevo Tour y mover las ventas a ese nuevo Tour.'
      } else if (message == 'The tour not delete') {
        title = 'Error Tickets vendidos';
        messageErr = 'Tienes tickets vendidos para este tour. No puedes eliminar este tour. Debes crear un nuevo Tour y mover las ventas a ese nuevo Tour. Para luego poder eliminar el tour.'
      }  else if (message == 'The number employee complete') {
        title = 'Error Usuarios permitidos';
        messageErr = 'Has completado el N° de usuarios de tú plan.'
      } else if (message == 'The number transportation complete') {
        title = 'Error Transportes permitidos';
        messageErr = 'Has completado el N° de transportes de tú plan.'
      } else if(message == 'Chair is occupied') {
        title = 'Error Silla ocupada';
        messageErr = 'La silla ya fue vendida.';
      } else if(message == 'Past date') {
        title = 'Error Fecha de salida';
        messageErr = 'No se pueden editar tours con fecha menor a la fecha actual.';
      }
      me.messageError(title, messageErr);
    },

    async del(uuid) {
      const me = this
      me.$bvModal.msgBoxConfirm('¿Seguro que deseas eliminar este registro?', {
        title: 'Confirmar eliminación',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Si',
        cancelTitle: 'No',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
        .then(async (value) => {
          if (value) {
            try {
              await me[me.repository].delete(uuid)

              me.messageSuccess("Registro eliminado", "Registro eliminado con exito");

              if (Object.prototype.hasOwnProperty.call(me, 'isTravel')) {
                me.$router.push("/tours");
              } else {
                await me.handleLoadData();
              }
            } catch (error) {
              const { message, status } = error.data;
              if (status == 419) {
                me.$bvModal.show("expired-token-model");
              } else if (status == 403) {
                me.$bvModal.show("session-finish-modal");
              }
              me.errorSave(message)
            }
          }
        })
    },

    messageValidation(error) {
      let text = null;

      // if (!error.required) {
      //   text = "Campo requerido";
      // }  
      if (error.email == false) {
        text = "Ingrese una dirección de correo válida.";
      } else if (error.numeric == false) {
        text = "Ingrese solo números."
      } else if (error.minLength == false) {
        text = `El campo debe tener mínimo ${error.$params.minLength.min} dígitos.`
      } else if (error.maxLength == false) {
        text = `El campo debe tener máximo ${error.$params.maxLength.max} dígitos.`
      }

      return text;
    }
  }
}