<template>
  <b-form @submit.prevent="saveData" ref="form" autocomplete="off">
    <b-row class="mb-2">
      <b-col lg="12" md="12" sm="12">
        <div class="label_input text-white">* Campos requeridos.</div>
      </b-col>
      <b-col lg="6" md="12" sm="12" class="mb-3">
        <div role="group">
          <label for="name" class="label_input">Nombre*</label>
          <b-form-input
            id="name"
            size="sm"
            placeholder="Nombre"
            v-model="formData.name"
            :disabled="isDisabled"
            autofocus
          />
        </div>
      </b-col>
      <b-col lg="6" md="12" sm="12" class="mb-3">
        <div role="group">
          <label for="nit" class="label_input">Nit*</label>
          <b-form-input
            id="nit"
            size="sm"
            placeholder="Nit"
            v-model="$v.formData.nit.$model"
            @blur="$v.formData.nit.$touch()"
            :disabled="isDisabled"
          />
          <span
            v-if="$v.formData.nit.$error"
            class="help-block text-danger text_error"
          >
            {{ messageValidation($v.formData.nit) }}
          </span>
        </div>
      </b-col>
      <b-col lg="6" md="12" sm="12" class="mb-3">
        <div role="group">
          <label for="address" class="label_input">Dirección</label>
          <b-form-input
            id="address"
            size="sm"
            placeholder="Dirección"
            v-model="formData.address"
            :disabled="isDisabled"
          />
        </div>
      </b-col>
      <b-col lg="6" md="12" sm="12" class="mb-3">
        <div role="group">
          <label for="cellphone" class="label_input">Celular*</label>
          <b-form-input
            id="cellphone"
            size="sm"
            placeholder="Celular"
            v-model="$v.formData.cellphone.$model"
            @blur="$v.formData.cellphone.$touch()"
          />
          <span
            v-if="$v.formData.cellphone.$error"
            class="help-block text-danger text_error"
          >
            {{ messageValidation($v.formData.cellphone) }}
          </span>
        </div>
      </b-col>

      <b-col lg="6" md="12" sm="12" class="mb-3">
        <div role="group">
          <label class="label_input">Estado*</label>
          <v-select
            :options="typeStatus"
            label="name"
            class="style-chooser"
            placeholder="Seleccione"
            v-model="formData.status"
            :disabled="isDisabled"
          >
            <div slot="no-options">Sin opciones</div>
          </v-select>
        </div>
      </b-col>
      <b-col lg="12" md="12" sm="12" class="mb-3">
        <div role="group">
          <label class="label_input">¿Debe?</label>
          <b-form-checkbox
            id="checkbox-1"
            v-model="formData.is_debt"
            name="checkbox-1"
            :value="true"
            :unchecked-value="false"
            size="lg"
          >
            <label class="label_input">{{ formData.is_debt ? "Si" : "No" }}</label>
          </b-form-checkbox>
        </div>
      </b-col>
    </b-row>
    <b-row v-if="!isDisabled" class="footer-modal-customer">
      <b-col cols="12" class="d-flex justify-content-end">
        <b-button
          type="submit"
          size="lg"
          class="btn btn-success w-width"
          :disabled="isBusy || $v.$invalid"
        >
          <b-spinner class="mr-2" v-if="isBusy" small />
          Guardar
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import {
  BCol,
  BRow,
  BButton,
  BForm,
  BSpinner,
  BFormInput,
  BFormCheckbox,
} from "bootstrap-vue";
import vSelect from "vue-select";
import {
  required,
  numeric,
  minLength,
  maxLength,
} from "vuelidate/lib/validators";
import ActionCRUD from "@/mixins/ActionCRUDsp";

export default {
  inject: ["enterpriseRepository"],
  components: {
    BCol,
    BRow,
    BButton,
    BForm,
    BFormInput,
    BSpinner,
    BFormCheckbox,
    vSelect,
  },
  mixins: [ActionCRUD],
  props: {
    record: {
      type: Object,
      default: null,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      plans: [],
      typeStatus: ["Activo", "Inactivo"],
      isBusy: false,
      formData: {
        name: "",
        nit: "",
        address: "",
        cellphone: "",
        is_debt: false,
      },
      repository: "enterpriseRepository",
    };
  },
  validations: {
    formData: {
      name: {
        required,
      },
      nit: {
        required,
        numeric,
      },
      address: {
        required,
      },
      cellphone: {
        required,
        numeric,
        minLength: minLength(10),
        maxLength: maxLength(10),
      },
    },
  },
  async mounted() {
    const me = this;
    me.formData = { ...me.formData, ...me.record };
    delete me.formData.photo;
  },
  methods: {},
};
</script>
