<template>
  <div>
    <b-container fluid>
      <b-row>
        <b-col cols="12" class="mt-4">
          <h3>Empresas</h3>
        </b-col>
      </b-row>
      <b-card no-body class="card-global overflow-hidden">
        <base-action-table
          :records="records"
          :fields="fields"
          :busy="isBusy"
          :filtering="filtering"
          :module="module"
          @creating="handleCreating"
          @detail="handleDetail"
          @editing="handleEditing"
          @deleting="handleDeleting"
          @payments="handlePayments"
        >
          <template #cell(is_debt)="data">
            {{ data.item.is_debt ? "Si" : "No" }}
          </template>
        </base-action-table>
      </b-card>
    </b-container>

    <base-modal :title="title" :id="id">
      <template v-slot:form>
        <form-enterprise :record="record" :isDisabled="isDisabled" @save="save" />
      </template>
    </base-modal>

    <base-modal title="Historial de Pagos" id="history-plan">
      <template v-slot:form>
        <Payment :enterprise="enterprise" :updated="handleLoadData" />
      </template>
    </base-modal>
  </div>
</template>

<script>
import { BContainer, BCol, BRow, BCard } from "bootstrap-vue";
import ActionCRUD from "@/mixins/ActionCRUDsp";
import BaseActionTable from "@/components/base/BaseActionTable";
import BaseModal from "@/components/base/BaseModal";
import FormEnterprise from "./FormEnterprise";
import Payment from "./components/Payment"
export default {
  inject: ["enterpriseRepository"],
  mixins: [ActionCRUD],
  components: {
    BContainer,
    BCol,
    BRow,
    BCard,
    BaseActionTable,
    BaseModal,
    FormEnterprise,
    Payment,
  },
  data() {
    return {
      records: [],
      record: null,
      fields: [
        {
          key: "name",
          label: "Nombre",
          sortable: true,
        },
        {
          key: "nit",
          label: "NIT",
          sortable: true,
        },
        {
          key: "cellphone",
          label: "Celular",
          sortable: true,
        },
        {
          key: "address",
          label: "Dirección",
          sortable: true,
        },
        {
          key: "is_debt",
          label: "Debe",
          sortable: true,
        },
        {
          key: "status",
          label: "Estado",
          sortable: true,
        },
        { key: "actions", label: "Accion" },
      ],
      isBusy: false,
      filtering: true,
      isDisabled: false,
      title: "Crear náutica",
      id: "enterprise-modal",
      module: "53a1c6b6-b2f6-4137-8838-98174e655120",
      repository: "enterpriseRepository",
      enterprise: null
    };
  },
  async mounted() {
    await this.handleLoadData();
  },
  methods: {
    handleCreating() {
      const me = this
      me.title = "Crear Náutica";
      me.isDisabled = false;

      me.record = null;
      me.$bvModal.show(me.id);
    },
    handleDetail(item) {
      const me = this;
      me.title = "Detalle Empresa";
      me.isDisabled = true;
      me.record = item
      me.$bvModal.show(me.id);
    },
    handleEditing(item) {
      const me = this;
      me.title = "Actualizar Empresa";
      me.isDisabled = false;
      me.record = item;
      me.$bvModal.show(me.id);
    },
    handlePayments(item) {
      const me = this;
      me.enterprise = item;
      me.$bvModal.show("history-plan");
    },
    async handleDeleting(id) {
      await this.del(id);
    },
  },
};
</script>

<style></style>
