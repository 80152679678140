import messageMixin from "./messageMixin";

export default {
  mixins: [messageMixin],
  mounted() {
    window.scroll(0, 0);
  },
  methods: {
    async handleLoadData() {
      const me = this;
      try {
        if (Object.prototype.hasOwnProperty.call(me, "isBusy")) {
          me.isBusy = true;
        }

        const { data } = await me[me.repository].getAll();
        me.records = data;
      } catch (error) {
        me.records = [];
        const { status } = error.data;
        if (status == 419) {
          me.$bvModal.show("expired-token-model");
        } else if (status == 403) {
          me.$bvModal.show("session-finish-modal");
        }
      } finally {
        if (Object.prototype.hasOwnProperty.call(me, "isBusy")) {
          me.isBusy = false;
        }
      }
    },

    async saveData() {
      const me = this;
      try {
        if (Object.prototype.hasOwnProperty.call(me, "isBusy")) {
          me.isBusy = true;
        }

        const uuid = me.formData.uuid ? me.formData.uuid : null;
        const option = uuid ? "update" : "save";

        await me[me.repository][option](uuid, me.formData);
        me.$emit("save");
      } catch (error) {
        const { message, status } = error.data;
        if (status == 419) {
          me.$bvModal.show("expired-token-model");
        } else if (status == 403) {
          me.$bvModal.show("session-finish-modal");
        }
        me.errorSave(message);
      } finally {
        if (Object.prototype.hasOwnProperty.call(me, "isBusy")) {
          me.isBusy = false;
        }
      }
    },

    async save() {
      const me = this;
      me.messageSuccess();
      me.$bvModal.hide(me.id);
      await me.handleLoadData();
    },

    errorSave(message) {
      const me = this;

      let title,
        messageErr = "";

      if (message == "Email is registered") {
        title = "Error Correo electrónico";
        messageErr = "Correo electrónico ya esta en uso.";
      } else if (
        message == "Username is registered" ||
        message == "Identification is registered"
      ) {
        title = "Error Identificación";
        messageErr = "Identificación ya esta en uso.";
      } else if (message == "NIT is registered") {
        title = "Error NIT";
        messageErr = "Nit ya esta en uso.";
      }
      me.messageError(title, messageErr);
    },

    async del(uuid) {
      const me = this;
      me.$bvModal
        .msgBoxConfirm("¿Seguro que deseas eliminar este registro?", {
          title: "Confirmar eliminación",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Si",
          cancelTitle: "No",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then(async (value) => {
          if (value) {
            try {
              await me[me.repository].delete(uuid);

              me.messageSuccess("Registro eliminado", "Registro eliminado con exito");
              me.handleLoadData();

            } catch (error) {
              const { status } = error.data;
              if (status == 419) {
                me.$bvModal.show("expired-token-model");
              } else if (status == 403) {
                me.$bvModal.show("session-finish-modal");
              }
            }
          }
        });
    },

    messageValidation(error) {
      let text = null;

      // if (!error.required) {
      //   text = "Campo requerido";
      // }
      if (error.email == false) {
        text = "Ingrese una dirección de correo válida.";
      } else if (error.numeric == false) {
        text = "Ingrese solo números.";
      } else if (error.minLength == false) {
        text = `El campo debe tener mínimo ${error.$params.minLength.min} dígitos.`;
      } else if (error.maxLength == false) {
        text = `El campo debe tener máximo ${error.$params.maxLength.max} dígitos.`;
      }

      return text;
    },
  },
};
