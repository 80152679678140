<template>
  <b-form @submit.prevent="saveData" ref="form" autocomplete="off">
    <b-row class="mb-3">
      <b-col lg="12" md="12" sm="12">
        <div class="label_input text-white">* Campos requeridos.</div>
      </b-col>
      <b-col lg="6" md="12" sm="12" class="mb-3">
        <div role="group">
          <label class="label_input">Plan*</label>
          <v-select
            :options="plans"
            label="name"
            class="style-chooser"
            placeholder="Seleccione"
            v-model="formData.planEnterprisePriceUuid"
            :reduce="(option) => option.uuid"
            :disabled="isDisabled"
            @input="getPricePlan"
          >
            <div slot="no-options">Sin opciones</div>
          </v-select>
        </div>
      </b-col>
      <b-col lg="6" md="12" sm="12" class="mb-3" v-if="formData.planEnterprisePriceUuid">
        <date-picker
          v-model="formData.initial_date"
          :attributes="attrs"
          :model-config="modelConfig"
          mode="date"
          @input="dateFinish"
        >
          <template v-slot="{ inputValue, inputEvents }">
            <b-row>
              <b-col lg="12" md="12" sm="12">
                <div role="group">
                  <label class="label_input">Fecha inicial*</label>
                  <b-form-input
                    size="sm"
                    :value="inputValue"
                    v-on="inputEvents"
                    :disabled="isDisabled"
                    readonly
                  />
                </div>
              </b-col>
            </b-row>
          </template>
        </date-picker>
      </b-col>
      <b-col lg="6" md="12" sm="12" class="mb-3" v-if="formData.planEnterprisePriceUuid">
        <div role="group">
          <label class="label_input">Fecha final</label>
          <b-form-input
            size="sm"
            v-model="formData.final_date"
            disabled
          />
        </div>
      </b-col>
      <b-col lg="6" md="12" sm="12" class="mb-3">
        <div role="group">
          <label for="payment_method" class="label_input">Método de pago*</label>
          <v-select
            :options="typeOfPayments"
            :reduce="(option) => option.id"
            label="name"
            class="style-chooser"
            placeholder="Seleccione"
            v-model="formData.payment_method"
          >
            <div slot="no-options">Sin opciones</div>
          </v-select>
        </div>
      </b-col>
      <b-col lg="6" md="12" sm="12" class="mb-3">
        <div role="group">
          <label for="discount" class="label_input">Descuento</label>
          <b-form-input
            id="discount"
            size="sm"
            type="number"
            placeholder="Precio"
            v-model="formData.discount"
            @keyup="generateDiscont"
            min="0"
            max="100"
          />
        </div>
      </b-col>
      <b-col lg="6" md="12" sm="12" class="mb-3">
        <div role="group">
          <label for="total" class="label_input">Total*</label>
          <b-form-input
            id="total"
            size="sm"
            placeholder="Total"
            v-model="formData.total"
            disabled
          />
        </div>
      </b-col>
      <b-col lg="12" md="12" sm="12" class="mb-3">
        <div role="group">
          <label class="label_input">¿Debe?</label>
          <b-form-checkbox
            id="checkbox-1"
            v-model="formData.is_debt"
            name="checkbox-1"
            :value="true"
            :unchecked-value="false"
            size="lg"
          >
            <label class="label_input">{{ formData.is_debt ? "Si" : "No" }}</label>
          </b-form-checkbox>
        </div>
      </b-col>
    </b-row>
    <b-row class="footer-modal-customer mb-3">
      <b-col cols="12" class="d-flex justify-content-end">
        <b-button
          type="submit"
          size="lg"
          class="btn btn-success w-width"
          :disabled="isBusy || $v.$invalid"
        >
          <b-spinner class="mr-2" v-if="isBusy" small />
          Guardar
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script lang="js">
import {
  BCol,
  BRow,
  BButton,
  BForm,
  BSpinner,
  BFormInput,
  BFormCheckbox,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { required } from "vuelidate/lib/validators";
import FormatMixin from "@/mixins/FormatMixin"
import DatePicker from "v-calendar/lib/components/date-picker.umd";
import messageMixin from "@/mixins/messageMixin";
import { formatNumber } from "@/utils";
import moment from "moment";
moment.locale("es");

export default {
  inject: ["planEnterprisePriceRepository", "historyPlanRepository"],
  mixins: [FormatMixin, messageMixin],
  components: {
    BCol,
    BRow,
    BButton,
    BForm,
    BFormInput,
    BSpinner,
    BFormCheckbox,
    vSelect,
    DatePicker,
  },
  props: {
    record: {
      type: Object,
      default: null,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    enterprise: {
      type: Object,
      default: null,
    },
  },
  data() {
    const me = this;
    return {
      isBusy: false,
      formData: {
        payment_method: '',
        initial_date: '',
        final_date: '',
        total: '',
        discount: 0,
        planEnterprisePriceUuid: '',
        enterpriseUuid: me.enterprise.uuid,
        is_debt: false,
      },
      typeOfPayments: [
        {
          id: "Efectivo",
          name: "Efectivo",
        },
        {
          id: "Bancolombia",
          name: "Bancolombia",
        },
        {
          id: "Bancolombia ahorro a la mano",
          name: "Bancolombia ahorro a la mano",
        },
        {
          id: "Daviplata",
          name: "Daviplata",
        },
        {
          id: "Nequi",
          name: "Nequi",
        },
        {
          id: "Banco Davivienda",
          name: "Banco Davivienda",
        },
        {
          id: "Banco Agrario",
          name: "Banco Agrario",
        },
        {
          id: "Banco AV Villas",
          name: "Banco AV Villas",
        },
        {
          id: "Banco BBVA Colombia S.A",
          name: "Banco BBVA C olombia S.A",
        },
        {
          id: "Banco de Bogota",
          name: "Banco de Bogota",
        },
        {
          id: "Banco de Occidente",
          name: "Banco de Occidente",
        },
        {
          id: "Banco Popular",
          name: "Banco Popular",
        },
      ],
      modelConfig: {
        type: "string",
        mask: "YYYY-MM-DD",
      },
      attrs: [
        {
          highlight: "orange",
          dates: new Date(),
        },
      ],
      plans: [],
    };
  },
  validations: {
    formData: {
      payment_method: {
        required,
      },
      total: {
        required,
      },
      planEnterprisePriceUuid: {
        required,
      },
      initial_date: {
        required,
      },
      final_date: {
        required,
      },
      discount: {
        required,
      }
    },
  },
  async mounted() {
    const me = this;
    await me.loadPlans();
    if (me.record) {
      me.formData = {
        initial_date: me.record.initial_date,
        final_date: me.record.final_date,
        planEnterprisePriceUuid: me.record.planEnterprisePriceUuid,
        enterpriseUuid: me.record.enterpriseUuid,
        is_debt: me.record.is_debt,
        payment_method: me.record.payment_method,
        total: me.record.total + '',
        uuid: me.record.uuid,
        discount: me.record.discount
      };
      me.formatPrice();
    }
  },
  methods: {
    async saveData() {
      const me = this
      try {
        me.isBusy = true;
        const uuid = me.formData.uuid ? me.formData.uuid : null;
        const option = me.formData.uuid ? 'update' : 'save';
        me.formData.total =  Number(me.formData.total?.split(".").join("").trim())

        await me.historyPlanRepository[option](uuid, me.formData);
        me.$emit('save');
      } catch (error) {
        const { status } = error.data;
        if (status == 419) {
          me.$bvModal.show("expired-token-model");
        } else if (status == 403) {
          me.$bvModal.show("session-finish-modal");
        }
        let title = 'Error al guardar registro' 
        let messageErr = 'Registro no guardado.';
        
        me.messageError(title, messageErr);
      } finally {
        me.isBusy = false;
      }
    },
    formatPrice() {
      const me = this;
      me.formData.total = formatNumber(me.formData.total);
    },
    async loadPlans() {
      const me = this;
      try {
        const { data } = await me.planEnterprisePriceRepository.getAll();

        data.sort((a, b) => {
          if (
            parseInt(a.planEnterprise.number_transportations) >
            parseInt(b.planEnterprise.number_transportations)
          ) {
            return 1;
          }
          if (
            parseInt(a.planEnterprise.number_transportations) <
            parseInt(b.planEnterprise.number_transportations)
          ) {
            return -1;
          }
          return 0;
        });

        me.plans = data.map((el) => ({
          name:
            el.planEnterprise.name +
            " " +
            el.type +
            " " +
            el.planEnterprise.number_users + " " +
            "U" +
            " " +
            el.planEnterprise.number_transportations +
            " " +
            "T",
          uuid: el.uuid,
          price: el.price,
          type: el.type
        }));
      } catch (error) {
        const { status } = error.data;
        if (status == 419) {
          me.$bvModal.show("expired-token-model");
        } else if (status == 403) {
          me.$bvModal.show("session-finish-modal");
        }
      }
    },
    dateFinish() {
      const me = this;
      let numberMonths = 1;
      const record = me.plans.find((el) => el.uuid == me.formData.planEnterprisePriceUuid);

      if(record){
        numberMonths = record.type == "Mensual" ? 1 : record.type == "Trimestral" ? 3 : record.type == "Semestral" ? 6 : 12
      }
       
      me.formData.final_date = moment(me.formData.initial_date)
        .add(numberMonths, "months")
        .format("YYYY-MM-DD");
    },
    generateDiscont() {
      const me = this;
      if (me.formData.discount > 0 && me.formData.discount <= 100) {
        const total = Number(me.total?.split(".").join(""));
        const discount = (Number(me.formData.discount) * total) / 100;
        me.formData.total = formatNumber((total - discount) + "");
      } else {
        me.formData.total = me.total
      }
    },
    getPricePlan() {
      const me = this;
      me.formData.initial_date = "";
      me.formData.final_date = "";
      const record = me.plans.find((el) => el.uuid == me.formData.planEnterprisePriceUuid);
      me.total = record ? formatNumber(record?.price + '') : 0
      me.formData.total = me.total;
    }
  },
};
</script>
