export default {
  methods: {
    format(attribute) {
      const me = this;
      let price = 0;

      if (attribute.split("$")[1]) {
        attribute = attribute.split("$")[1].split(".").join("");

        if (isNaN(attribute)) attribute = 0;
      } else {
        if (isNaN(attribute)) attribute = 0;
      }

      const formatterPeso = new Intl.NumberFormat("es-CO", {
        style: "currency",
        currency: "COP",
        minimumFractionDigits: 0,
      });

      price = formatterPeso.format(attribute);
      
      return price
    },
  },
}