<template>
  <div>
    <b-row class="mt-2">
      <b-col cols="12">
        <base-action-table
          :records="records"
          :fields="fields"
          :busy="isBusy"
          :filtering="filtering"
          :module="module"
          @creating="handleCreating"
          @editing="handleEditing"
          @deleting="handleDeleting"
        >
          <template #cell(total)="data">
            {{ formatPrice(data.item.total)}}
          </template>
          <template #cell(plan)="data">
            {{ data.item.planEnterprisePrice.planEnterprise.name }} - {{ data.item.planEnterprisePrice.type }}
          </template>
          <template #cell(is_debt)="data">
            {{ data.item.is_debt ? "Si" : "No" }}
          </template>
        </base-action-table>
      </b-col>
    </b-row>
    <base-modal :title="title" :id="id">
      <template v-slot:form>
        <form-payment :record="record" :enterprise="enterprise" @save="updatePayment" :isDisabled="isDisabled" />
      </template>
    </base-modal>
  </div>
</template>

<script>
import {
  BCol,
  BRow,
} from "bootstrap-vue";
import BaseActionTable from "@/components/base/BaseActionTable";
import BaseModal from "@/components/base/BaseModal";
import FormPayment from "./FormPayment";
import ActionCRUD from "@/mixins/ActionCRUD";

export default {
  inject: ['historyPlanRepository'],
  mixins: [ActionCRUD],
  components: {
    BCol,
    BRow,
    BaseActionTable,
    BaseModal,
    FormPayment,
  },
  props: {
    enterprise: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      record: null,
      records: [],
      fields: [
        {
          key: "payment_method",
          label: "Método de pago",
          sortable: true,
        },
        {
          key: "total",
          label: "Total",
          sortable: true,
        },
        {
          key: "plan",
          label: "Plan",
          sortable: true,
        },
        {
          key: "initial_date",
          label: "Fecha Inicial",
          sortable: true,
        },
        {
          key: "final_date",
          label: "Fecha Final",
          sortable: true,
        },
        {
          key: "is_debt",
          label: "Debe",
          sortable: true,
        },
        { key: "actions", label: "Accion" },
      ],
      isBusy: false,
      filtering: false,
      isDisabled: false,
      id: "pagar-modal",
      title: "Agregar Pago",
      module: "3dc8ce3f-cdee-4f48-9b4e-c9573d5926e4",
    }
  },
  mounted() {
    const me = this
    me.loadPayments();
  },
  methods: {
    async loadPayments() {
      const me = this
      try {
        me.isBusy = true;
        const { data } = await me.historyPlanRepository.getAll(me.enterprise.uuid);
        me.records = data;
      } catch (error) {
        const { status } = error.data;
        if (status == 419) {
          me.$bvModal.show("expired-token-model");
        } else if (status == 403) {
          me.$bvModal.show("session-finish-modal");
        }
      } finally {
        me.isBusy = false;
      }
    },
    handleCreating() {
      const me = this;
      me.title = "Agregar Pago";
      me.isDisabled = false;
      me.record = null;
      me.$bvModal.show(me.id);
    },
    handleEditing(item) {
      const me = this;
      me.title = "Actualizar Historial";
      me.isDisabled = true;
      me.record = item;
      me.$bvModal.show(me.id);
    },
    updatePayment() {
      const me = this;
      me.messageSuccess();
      me.loadPayments();
      me.$emit("updated")
      me.$bvModal.hide(me.id);
    },
    formatPrice(price) {
      const formatterPeso = new Intl.NumberFormat("es-CO", {
        style: "currency",
        currency: "COP",
        minimumFractionDigits: 0,
      });

      return formatterPeso.format(parseFloat(price));
    },
    async handleDeleting(uuid) {
      const me = this
      me.$bvModal.msgBoxConfirm('¿Seguro que deseas eliminar este registro?', {
        title: 'Confirmar eliminación',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Si',
        cancelTitle: 'No',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
        .then(async (value) => {
          if (value) {
            try {
              await me.paymentRepository.delete(uuid)

              me.messageSuccess('Registro eliminado', 'Registro eliminado con exito');
              me.loadPayments();
              me.$bvModal.hide(me.id);
              me.$emit("loadSales");
            } catch (error) {
              const { status, message } = error.data;
              if (status == 419) {
                me.$bvModal.show("expired-token-model");
              } else if (status == 403) {
                me.$bvModal.show("session-finish-modal");
              }

              let title = 'Error al eliminar registro' 
              let messageErr = 'Registro no Eliminado.';

              me.messageError(title, messageErr, "icon-message-error");
            }
          }
        })
    },
  },
}
</script>